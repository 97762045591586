import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
  color: rgba(255, 255, 255, .85);
  cursor: default;
  user-select: none;
`

const HeroTitle: React.FC = () => {
  return (
    <H1 className="fixed-width mb-4">
      <span style={{ fontSize: `0.75em`, textTransform: `lowercase` }}>Протоиерей</span>
      <br />
      <span>
        <span className="secondary-color">А</span>ртемий{' '}
        <span className="secondary-color">В</span>ладимиров
      </span>
    </H1>
  )
}

export default HeroTitle