import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'

const H2 = styled.h2`
  font-family: "Marck Script";
  color: rgba(255, 255, 255, .85);
  font-size: 20px;
  line-height: 1.3;
`

const AboutLink = styled(Link)`
  font-family: 'Marck Script';
  font-size: 20px;
  color: rgb(253, 165, 255);
  padding: 9px 22px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  transition: background 0.3s;
  &:link {
    color: rgb(253, 165, 255);
  }
  &:visited {
    color: rgb(253, 165, 255);
  }
  &:active {
    color: rgb(253, 165, 255)
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const HeroSubtitle: React.FC = () => {
  const data = useStaticQuery(graphql`
    query metadataForSubtitle {
      site {
        siteMetadata {
          headerText
        }
      }
    }
  `)

  return (
    <>
      <H2 className="fixed-width mb-2">
        {data.site.siteMetadata.headerText}
      </H2>
      <noindex>
        <AboutLink to="/about">
          подробнее обо мне... &gt;
        </AboutLink>
      </noindex>
    </>
  )
}

export default HeroSubtitle