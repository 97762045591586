import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { GetHeadPhotoQuery } from '~/types'

const Wrapper = styled.div`
  width: 110px;
  height: 110px;
  overflow: hidden;
  margin: auto;
  border-radius: 100%;
  border: 5px solid rgba(255, 255, 255, .85);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
`

const HeadPhoto: React.FC = () => {
  const data: GetHeadPhotoQuery = useStaticQuery(graphql`
    query getHeadPhoto {
      file(relativePath: { eq: "header-photo-5.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  
  return (
    <Wrapper className="mb-3" onClick={() => navigate('/about')}>
      <Img
        fixed={data.file.childImageSharp.fixed}
        //@ts-ignore
        objectFit="cover"
        objectPosition="50% 50%"
      />
    </Wrapper>
  )
}

export default HeadPhoto