import React, { useRef, useEffect, useState, HTMLProps } from 'react'
import styled from 'styled-components'
import { useAppContext } from '~/contexts/appContext'
import { useSwipeable } from 'react-swipeable'
import { constants } from 'os'
import { fromEvent } from 'rxjs'

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-items: flex-start;
  align-items: flex-start;
  transition: left .5s ease, height .5s ease;
  overflow: hidden;
  background: rgb(66, 25, 109);
`

const Slide = styled.div`
  align-self: flex-start;
  /* padding: 20px; */
`

const Dots = styled.div`
  position: absolute;
  bottom: 10px;
  height: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-items: center;
  align-items: center;
`

const Dot = styled.span`
  width: 16px;
  height: 16px;
  background: white;
  margin: 4px 8px;
  border-radius: 12px;
  &.active {
    background: #8642C8;
    border: 2px solid #F1F1F1;
    box-sizing: border-box;
  }
`

const Space = styled.span`
  flex: 1;
`
// extends HTMLProps<HTMLDivElement>
interface ISliderProps {
  className?: string,
  style?: React.CSSProperties
}

const Slider: React.FC<ISliderProps> = ({children, ...props}) => {
  const ctx = useAppContext()
  const containerRef = useRef<HTMLDivElement>()
  const [width, setWidth] = useState(null)
  const [slideHeight, setSlideHeight] = useState(null)
  const [autoslide, setAutoslide] = useState<boolean>(true)
  const items = children as React.ReactNode[]
  const [ currentIndex, setCurrentIndex ] = useState(Math.round(Math.random() * 1000) % items.length)
  // solution: https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
  const [slideRefs, setSlideRefs] = React.useState([])

  const handlers = useSwipeable({
    onSwipedLeft: e => {
      // e.event.stopImmediatePropagation()
      currentIndex < items.length - 1 && setCurrentIndex(currentIndex + 1)
    },
    onSwipedRight: e => {
      // e.event.stopImmediatePropagation()
      currentIndex > 0 && setCurrentIndex(currentIndex - 1)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  })

  // useEffect(() => {
  //   const f = () => {
  //     console.log('slide', autoslide, items.length, currentIndex, (currentIndex + 1) % items.length)
  //     if (autoslide) {
  //       setCurrentIndex((currentIndex + 1) % items.length)
  //     }
  //   }
  //   const h = setInterval(f, 1000)
  //   return () => clearInterval(h)
  // }, [])

  // useEffect(() => {
  //   const disable = () => setAutoslide(false)
  //   const enable = () => setAutoslide(true)
  //   containerRef.current.addEventListener('mouseenter', disable)
  //   containerRef.current.addEventListener('mouseleave', enable)
  //   return () => {
  //     containerRef.current.removeEventListener('mouseenter', disable)
  //     containerRef.current.removeEventListener('mouseleave', enable)
  //   }
  // }, [])

  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect()
    setWidth(containerRect.width)
  }, [ctx.state.pageWidth])

  useEffect(() => {
    setSlideRefs(slideRefs => (
      Array(items.length).fill().map((_, i) => slideRefs[i] || React.createRef())
    ))
  }, [items.length])

  useEffect(() => {
    if (slideRefs && slideRefs[currentIndex] && slideRefs[currentIndex].current) {
      // for (const slideRef of slideRefs) {
      //   const slideRect = slideRef.current.getBoundingClientRect()
      //   console.log(slideRect.height)
      // }
      const slideRect = slideRefs[currentIndex].current.getBoundingClientRect()
      setSlideHeight(slideRect.height)
    }
  }, [slideRefs, currentIndex, ctx.state.pageWidth])

  return (
    <Container {...handlers} ref={containerRef} {...props}>
      {width && (
        <InnerContainer
          style={{
            width: `${width * items.length}px`,
            left: `-${currentIndex * width}px`,
            height: `${slideHeight}px`
          }}
        >
          {items.map((child, index) => {
            return (
              <Slide key={index} style={{ width: `${width}px` }} ref={slideRefs[index]}>
                {child}
              </Slide>
            )
          })}
        </InnerContainer>
      )}
      <Dots>
        <Space></Space>
        {(items && items.length > 1) && items.map((item, index) => {
          return <Dot key={index} className={currentIndex === index ? 'active' : ''} onClick={() => setCurrentIndex(index)}></Dot>
        })}
        <Space></Space>
      </Dots>
    </Container>
  )
}

export default Slider