import React from 'react'
import styled from 'styled-components'
import HeadPhoto from './HeadPhoto'
import HeadTitle from './HeadTitle'
import HeadSubtitle from './HeadSubtitle'
import bg1x from './assets/bg-160.png'
import bg2x from './assets/bg-160@2x.png'

const Head = styled.div`
    width: 100%;
    height: 70vh;
    min-height: 560px;
    background: rebeccapurple;
    background-image: url(${bg1x});
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
      background-image: url(${bg2x});
      background-size: 160px 160px
    }
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    justify-items: center;
    text-align: center;
  `

const Row = styled.div`
    flex: 0 1 auto;
    align-self: auto;
  `
const Space = styled.div`
    flex: 1;
  `

const HeroTitle: React.FC = () => {
  return (
    <Head>
      <Space></Space>
      <Row><HeadPhoto /></Row>
      <Row><HeadTitle /></Row>
      <Row><HeadSubtitle /></Row>
      <Space></Space>
    </Head>
  )
}

export default HeroTitle